@tailwind base;
@tailwind components;
@tailwind utilities;


  :root {
    --header-mobile-height: 79px;
    --header-mobile-negative-height: -79px;
    --header-desktop-height: 156px;
    --header-desktop-negative-height: -156px;
  }


@layer base {


  textarea:focus,
  input:focus {
    outline: none;
  }

  input:-internal-autofill-selected {
    background-color: unset !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  }

  select {
    margin-right: 1rem;
  }

  #form-create-club {
    padding-top: var(--header-desktop-height);
    margin-top: var(--header-desktop-negative-height);
  }
  @media (max-width: 922px) {
    #form-create-club {
      padding-top: var(--header-mobile-height);
      margin-top: var(--header-mobile-negative-height);
    }
  }

  body {
    color: #2e2e2e;
    @apply font-roboto;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected,
  .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected:focus-visible,
  .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected:hover {
    @apply bg-event rounded-lg;
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    @apply text-dark-grey;
  }

  .css-13cymwt-control {
    @apply p-1;
  }


  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 40px;
    @apply text-secondary font-sora font-bold
  }
  h2, .h2 {
    margin-bottom: 2rem;
    @apply text-secondary text-3xl font-bold font-roboto
  }
  h3, .h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    @apply text-secondary text-2xl font-bold font-roboto
  }
}

@screen md {
    .main-logo {
      width: 100%;
      height: 4rem;
      min-height: 3rem;
    }
}

@layer components {


  .scroll-picture{
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .scroll-picture::-webkit-scrollbar {
    display: none;
  }

  .map-marker{
    @apply w-8 h-8 border-4 border-white rounded-full bg-concept-green;
    &.format-fortnightly{
      @apply bg-event;
    }
    &.format-weekly{
      @apply bg-primary;
    }
  }

  .map-cluster-label{
    @apply bg-primary rounded-full w-8 h-8 justify-center items-center flex font-bold;
  }


  .button {
    @apply rounded-full disabled:cursor-not-allowed text-nowrap font-bold border-2 py-2 px-6 md:max-2xl:px-4 disabled:opacity-25 md:max-2xl:text-sm
  }
  .skewed-background {
    display: inline-block;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: calc(100% + 0.4rem);
      height: 100%;
      background: #ffd108;
      transform: rotate(-2deg) translateX(-0.2rem);
    }

    .large:before {
      width: calc(100% + 0.8rem);
      height: calc(100% + 0.6rem);
      transform: rotate(-2deg) translate(-0.4rem, -0.3rem);
    }

    .exponent {
      font-size: 0.6rem;
      vertical-align: super;
    }
  }

  .video-thumbnail-icon {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s ease-in-out;
  }

  .title-banner {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
                      90deg,
                      rgba(0, 0, 0, 0.8) 0%,
                      rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  .mobile-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center center;
    background-size: cover;
  }

  .quote-card {
    @apply font-sora relative;

    .background-container {
      @apply rounded overflow-hidden p-12 text-2xl font-bold text-center;
    }

    .quote-icon {
      @apply flex relative mx-8 md:mx-24;
      transform: translateY(50%);
    }

    .quote-icon.after {
      justify-content: end;
      transform: translateY(-50%);
    }
  }

  .new-label-triangles {
    @apply flex w-full h-5 bg-info-blue;
    & > div {
      width: 95%;
      @apply relative h-full mx-auto bg-info bottom-[-1px];
      clip-path: polygon(-1% 101%, 101% 101%, 50% -1%);
    }
  }

  .filter-dark-blue-transparent {
    background: linear-gradient(to right, #234653, #234653, #23465358);
  }
  .filter-dark-transparent {
    background: linear-gradient(
                    to right,
                    #000000c4,
                    #000000c4,
                    transparent,
                    transparent
    );
  }

  .embla__container {
    display: flex;
  }
  .embla__slide {
    flex: 0 0 100%;
  }

  .carousel-home {
    display: flex;
    justify-content: center;
    width: 100vw;
    overflow: hidden;
    .embla {
      width: 80vw;
    }
    .embla__slide {
      flex: 0 0 50%;

      min-width: 0;
      &.is-not-selected {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
  .embla__controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .embla__buttons {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }
  .embla__button {
    color: black;
    height: 2.5rem;
    width: 2.5rem;
    border: 3px solid black;
    border-radius: 50%;
    padding: 0.5rem;
  }
  .embla__dots {
    margin: 0 1rem;
  }
  .embla__dot {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 1rem;
    background-color: #80808054;
    margin: 0 0.3rem;
  }
  .embla__dot--selected {
    width: 2rem;
    background-color: #ee4540;
  }
  .header {
    line-height: 50px;
    @apply text-white mb-5
  }
  .main-logo {
    width: 100%;
    height: 4rem;
    min-height: 3rem;
  }
  .sub-title {
    font-size: 20px;
    line-height: 26px;
  }
  .dropdown-shadow {
    box-shadow: 0px 0px 6px #0000001A;
  }
  .toggle-card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    @apply bg-white;
  }

  .capital-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }

  .radio-checkmark:after {
    content: " ";
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: #fff;
    clip-path: polygon(16% 41%, 36% 61%, 84% 13%, 99% 28%, 36% 91%, 1% 56%);
    opacity: 1;
    transition: all 300ms ease;
  }
}






@media (max-width: 950px) {
  .filter-dark-blue-transparent {
    background: #234653bf;
  }
  .filter-dark-transparent {
    background: linear-gradient(to right, #000000c4, transparent, transparent);
  }
  .carousel-home {
    display: flex;
    justify-content: center;
    width: 80vw;
    overflow: hidden;
    .embla {
      width: 80vw;
    }
    .embla__slide {
      flex: 0 0 100%;
    }
  }
}




//@layer utilities {
//  .text-balance {
//    text-wrap: balance;
//  }
//}

//.example{
//  @apply border bg-blue-500 text-white p-4;
//}

